import React from 'react';
import ContentWrapper from '../components/ContentWrapper';
import Team from '../components/Team';
import Title from '../images/titles/kontakt.svg';


const About = () => {
    return  <ContentWrapper>
        <img src={Title} className="w-72 text-center mx-auto mt-16 mb-12" />
        <Team />
      </ContentWrapper>
}

export default About;