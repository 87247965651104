import React, { useEffect } from 'react';
const ProfileCard = ({img, name, title, email, phone}) => {

  return(
      <div className="profile-card m-4 sm:mb-8 text-center">
        <img src={img} style={{height: "244px"}} className="mb-4 rounded-full"/>
        <p className="mb-4 font-bold text-lg text-gray-700">{name}</p>
        <p className="text-lg mb-2">{title}</p>
        <div>
          <p>{email}</p>
          <p>{phone}</p>
        </div>
    </div>
  );
}

export default ProfileCard;