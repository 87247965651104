import React from 'react';
import Peter from '../images/Peter.jpg';
import Magne from '../images/Magne.jpg';
import Audun from '../images/Audun.jpg';
import ProfileCard from './ProfileCard';

const Team = () => {
  return (
    <>
        <div className="flex flex-wrap justify-center md:justify-between  mt-16">
          <ProfileCard img={Peter} name="Peter A Clayborough" title="Daglig leder/animatør" email="peter@thebranch.no" phone="+47 98 40 85 45"/>
          <ProfileCard img={Magne} name="Magne Skagen" title="Kreativ leder/animatør" email="magne@thebranch.no" phone="+47 41 25 68 21"/>
          <ProfileCard img={Audun} name="Audun A. E. Løkås" title="Prosjektleder/animatør" email="audun@thebranch.no" phone="+47 41 36 78 52"/>
        </div>
    </>
  );
}

export default Team;